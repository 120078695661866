import React from 'react';
import NextError from 'next/error';
import PropTypes from 'prop-types';
import Raven from 'raven';

// Components
import Error from '../src/components/Error';

const SENTRY_DSN = process.env.SENTRY_DSN;

class ErrorPage extends React.PureComponent {
  static async getInitialProps(ctx) {
    if (ctx.err) {
      Raven.config(SENTRY_DSN).install();
      Raven.captureException(ctx.err);
    }
    const errorInitialProps = await NextError.getInitialProps(ctx);
    return errorInitialProps;
  }

  render() {
    return <Error statusCode={this.props.statusCode} />;
  }
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number
};

export default ErrorPage;
